<script setup lang="ts">
import WeLogo from '/assets/images/weLogo.svg'

const { locale, locales } = useI18n()
const switchLocalePath = useSwitchLocalePath()
const { data: contactInfoData, error, status } = await useContactData()
const availableLocales = locales.value.filter((l) => {
  return l.code !== locale.value
})
const now = new Date()

function splitAddress(address: string) {
  const splitIndex = address.search(/\d{5}/) // Trova l'indice del codice postale
  return {
    beforePostalCode:
      splitIndex !== -1 ? address.slice(0, splitIndex).trim() : address,
    postalCodeAndAfter:
      splitIndex !== -1 ? address.slice(splitIndex).trim() : '',
  }
}
</script>

<template>
  <footer class="py-8 overflow-hidden lg:pt-14 lg:pb-8">
    <Container>
      <div class="flex flex-wrap justify-between lg:flex-row">
        <div class="flex">
          <!-- Logo -->
          <div class="flex-shrink-0 pr-6 sm:pr-12">
            <WeLogo class="w-[86px] lg:w-[123px]" />
          </div>

          <!-- Address -->
          <div class="grid grid-cols-1 gap-2 sm:grid-cols-2 sm:gap-x-12">
            <div
              v-for="office in contactInfoData?.offices?.slice(0, 2)"
              class="flex flex-col pb-2 sm:pb-0 last:pb-0"
              :key="office.id"
            >
              <p class="font-semibold leading-snug text-20">
                {{ office?.title }}
              </p>
              <p class="font-medium leading-snug text-18">
                {{ splitAddress(office?.address).beforePostalCode }}<br />
                {{ splitAddress(office?.address).postalCodeAndAfter }}
              </p>
              <a
                class="text-18 leading-snug font-medium underline underline-offset-[.125em] break-words"
                :href="`tel:${office.phone_number}`"
              >
                t: {{ office.phone_number }}
              </a>
              <a
                class="text-18 leading-snug font-medium underline underline-offset-[.125em] break-words"
                :href="`mailto:${office.email}`"
              >
                m: {{ office.email }}
              </a>
            </div>
          </div>
        </div>

        <!-- Lang switch -->
        <div
          class="flex w-full mt-8 ml-[86px] pl-6 sm:pl-12 sm:order-2 md:ml-0 md:pl-0 md:mt-0 md:w-1/12"
        >
          <div
            class="flex flex-col items-start leading-normal text-18 md:mr-0 md:ml-auto lg:text-20 richtext"
          >
            <a
              v-for="currLocale in availableLocales"
              :key="currLocale.code"
              class="inline-block whitespace-nowrap"
              :href="switchLocalePath(currLocale.code)"
            >
              {{ currLocale.name }}
            </a>
          </div>
        </div>
      </div>

      <!-- Copyright & Service menu -->
      <div
        class="flex items-center justify-between mt-8 font-medium lg:mt-10 text-14 lg:text-16"
      >
        <p class="sm:ml-[86px] sm:pl-12 lg:ml-[123px]">
          &copy; {{ now.getFullYear() }}
        </p>

        <nav class="space-x-2 md:pl-[3px] flex">
          <NuxtLinkLocale to="imprint">
            {{ $t('imprint') }}
          </NuxtLinkLocale>
          <span>|</span>
          <a
            href="https://otto.welance.com"
            rel="nofollow"
            target="_blank"
            class=""
          >
            Login
          </a>
        </nav>
      </div>
    </Container>
  </footer>
</template>
