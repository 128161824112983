export const useContactData = async () => {
  const { $directus, $readSingleton } = useNuxtApp()
  const { localeProperties } = useI18n()

  const langIso = localeProperties.value.iso || ''

  return useAsyncData(
    `${langIso}/contact_info`,
    () =>
      $directus.request(
        $readSingleton('projects_info', {
          fields: [
            'id',
            'email_contact',
            {
              offices: ['address', 'email', 'phone_number', 'title', 'lat_lng'],
            },
            {
              translations: ['id', 'languages_code', 'contacts'],
            },
          ],
        })
      ),
    {
      // Store current translation
      transform: (contactInfo) => ({
        ...contactInfo,
        currentTranslation: contactInfo.translations?.find(
          ({ languages_code }) => languages_code === langIso
        ),
      }),
    }
  )
}
