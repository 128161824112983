<script setup lang="ts">
import {
  CollapsibleContent,
  CollapsibleRoot,
  CollapsibleTrigger,
  DialogClose,
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
  DialogTitle,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuRoot,
  NavigationMenuTrigger,
  VisuallyHidden,
} from 'radix-vue'
import { ref, watch } from 'vue'
import WelanceLogo from '~/assets/images/welanceLogo.svg'
import WeLogo from '~/assets/images/weLogo.svg'
import { type Menu } from '~/types/Menu'
import LottieWrapper from '@/components/LottieWrapper.vue'
const { toggleBubbleVisibility, setLocale } = useChatWoot()

const departmentCtaPresent = ref(false)

// Listen for the event from DepartmentCta
const handleDepartmentCtaStatus = (event: Event) => {
  const customEvent = event as CustomEvent
  departmentCtaPresent.value = customEvent.detail
  toggleBubbleVisibility(departmentCtaPresent.value ? 'hide' : 'show')
}

// Get current locale info
const { localeProperties, t } = useI18n()
const langIso = localeProperties.value.iso

// Get menu data from API route
const { data } = await useFetch<Menu>(`/api/menu/${langIso}`)

function translateMenu(tree: Menu | null): Menu {
  if (!tree || !tree.length) {
    return []
  }

  return tree.map((node) => {
    const transformedNode: Menu[number] = {
      ...node,
      title: node.title.startsWith('__')
        ? t(node.title.split('__')[1])
        : node.title,
    }

    if (node.children && node.children.length) {
      transformedNode.children = translateMenu(node.children)
    }

    return transformedNode
  })
}
const menu = translateMenu(data.value)

// Mobile menu overlay state
const mobileMenuOpen = ref(false)
function toggleMenuOpen() {
  mobileMenuOpen.value = !mobileMenuOpen.value
}

// Get contact overlay data
const { data: contactInfoData, error, status } = await useContactData()
const now = new Date()

if (status.value === 'error') {
  throw error
}

// Contact overlay state
const contactOpen = ref(false)

function setContactOpen(value: boolean) {
  contactOpen.value = value
}

// watch(contactOpen, (isOpen) => {
//   if (!departmentCtaPresent.value) {
//     toggleBubbleVisibility(isOpen ? 'hide' : 'show')
//   }
// })

const route = useRoute()
watch(
  () => route.path,
  () => {
    contactOpen.value = false
    mobileMenuOpen.value = false
    if (!departmentCtaPresent.value && window && window.$chatwoot) {
      toggleBubbleVisibility('show')
    }
  },
  { deep: true, immediate: true }
)

function isActive(item: Menu[number]): boolean {
  // Remove leading language code (e.g., '/en/' or '/fr/') from the route path
  const normalizedPath = route.path.replace(/^\/[a-z]{2}(?=\/)/, '')

  if (normalizedPath === item.to) {
    return true
  }
  if (item.children) {
    return item.children.some((child) => isActive(child))
  }
  return false
}

onMounted(() => {
  window.addEventListener('chatwoot:ready', function () {
    const appLanguage = localeProperties.value.code
    setLocale(appLanguage)
  })
  // Listen for the event from DepartmentCta
  window.addEventListener(
    'department-cta-status',
    handleDepartmentCtaStatus as EventListener
  )
})

onUnmounted(() => {
  window.removeEventListener(
    'department-cta-status',
    handleDepartmentCtaStatus as EventListener
  )
})
</script>

<template>
  <header class="fixed top-0 inset-x-0 bg-white z-[99] h-[76px] lg:h-[106px]">
    <div class="h-full px-5 sm:px-12">
      <div class="flex items-center justify-between h-full gap-x-4">
        <!-- Logo -->
        <div
          class="w-[125px] h-[40px] relative overflow-hidden lg:w-[160px] lg:h-[60px] -ml-[10px]"
        >
          <NuxtLinkLocale :to="'/'" class="block w-full h-full">
            <strong class="sr-only">welance</strong>
            <div
              class="w-[170px] h-[170px] absolute -left-[20px] top-[17px] -translate-y-1/2 lg:w-[230px] lg:h-[230px] lg:-left-[30px] lg:top-[27px]"
            >
              <LottieWrapper
                :src="`/animations/logo-reveal.json`"
                autoplay
                :speed="1"
                renderer="svg"
              />
            </div>
          </NuxtLinkLocale>
        </div>

        <!-- Actions -->
        <div class="flex items-center headerMenu gap-x-4 lg:gap-x-10">
          <!-- Desktop menu -->
          <!--- TODO refactor navigation bar, should create abstraction over radix -->
          <NavigationMenuRoot
            as="nav"
            class="hidden lg:block"
            :delay-duration="100"
          >
            <NavigationMenuList class="flex items-center gap-x-10">
              <NavigationMenuItem
                v-for="item in menu"
                :key="item.id"
                class="relative"
              >
                <NavigationMenuLink v-if="!item.children?.length" as-child>
                  <NuxtLinkLocale
                    :to="item.to"
                    :class="[
                      'inline-block relative py-4 text-18 leading-none font-semibold',
                    ]"
                    active-class="activeMenuItem decoration-2"
                  >
                    {{ item.title }}
                  </NuxtLinkLocale>
                </NavigationMenuLink>

                <template v-else>
                  <!-- Submenu trigger -->
                  <NavigationMenuTrigger
                    :class="[
                      'inline-block subnav-trigger relative cursor-default py-4 text-18 leading-none font-semibold',
                      {
                        activeMenuItem: isActive(item),
                        'decoration-2': isActive(item),
                      },
                    ]"
                  >
                    {{ item.title }}
                  </NavigationMenuTrigger>

                  <!-- Submenu -->
                  <NavigationMenuContent
                    class="absolute z-30 top-full left-0 -ml-5 -mr-5 w-max max-w-[270px] rounded-lg py-2.5 px-5 bg-white data-[state='closed']:hidden shadow-lg"
                  >
                    <ul class="space-y-0.5">
                      <li v-for="child in item.children" :key="child.id">
                        <NavigationMenuLink
                          as-child
                          v-if="!child?.children?.length"
                        >
                          <NuxtLinkLocale
                            :to="`${child.to}`"
                            class="relative inline-block py-3 font-semibold leading-tight text-18"
                            active-class="activeMenuItem decoration-2"
                          >
                            {{ child.title }}
                          </NuxtLinkLocale>
                        </NavigationMenuLink>
                        <template v-else>
                          <NavigationMenuRoot>
                            <NavigationMenuItem as-child class="relative">
                              <NavigationMenuTrigger as-child>
                                <NavigationMenuLink as-child>
                                  <NuxtLinkLocale
                                    :to="`${child.to}`"
                                    class="relative inline-block py-3 font-semibold leading-tight text-18"
                                    active-class="activeMenuItem decoration-2"
                                  >
                                    {{ child.title }}
                                  </NuxtLinkLocale>
                                </NavigationMenuLink>
                              </NavigationMenuTrigger>
                              <NavigationMenuContent
                                class="absolute left-full w-max bg-white shadow-lg rounded-lg py-2.5 px-5 top-0 ml-2 data-[state='closed']:hidden"
                              >
                                <NavigationMenuList>
                                  <NavigationMenuItem
                                    v-for="subChild in child.children"
                                    :key="subChild.id"
                                  >
                                    <NavigationMenuLink as-child>
                                      <NuxtLinkLocale
                                        :to="`${subChild.to}`"
                                        class="relative inline-block py-3 font-semibold leading-tight text-18"
                                        active-class="activeMenuItem decoration-2"
                                      >
                                        {{ subChild.title }}
                                      </NuxtLinkLocale>
                                    </NavigationMenuLink>
                                  </NavigationMenuItem>
                                </NavigationMenuList>
                              </NavigationMenuContent>
                            </NavigationMenuItem>
                          </NavigationMenuRoot>
                        </template>
                      </li>
                    </ul>
                  </NavigationMenuContent>
                </template>
              </NavigationMenuItem>
            </NavigationMenuList>
          </NavigationMenuRoot>

          <!-- Contact toggler -->
          <div :class="[{ hidden: mobileMenuOpen }, 'lg:block']">
            <WeButton variant="ghost" @click="setContactOpen(true)">{{
              $t('contact')
            }}</WeButton>
          </div>

          <!-- Mobile menu toggler -->
          <button
            class="flex items-center justify-center lg:hidden"
            @click="toggleMenuOpen"
          >
            <template v-if="mobileMenuOpen">
              <span class="sr-only">{{ $t('close-menu') }}</span>
              <Icon name="close" aria-hidden="true" weight="700" />
            </template>
            <template v-else>
              <span class="sr-only">{{ $t('open-menu') }}</span>
              <Icon name="menu" aria-hidden="true" large />
            </template>
          </button>
        </div>
      </div>
    </div>

    <!-- Mobile menu overlay -->
    <DialogRoot v-model:open="mobileMenuOpen" :modal="false">
      <DialogPortal>
        <!-- Overlay content -->
        <Transition
          enter-active-class="duration-300 ease-out"
          enter-from-class="-translate-x-full "
          enter-to-class=""
          leave-active-class="duration-200 ease-in"
          leave-from-class=""
          leave-to-class="-translate-x-full"
        >
          <DialogContent
            class="transition transform fixed z-[9999] bottom-0 w-screen top-[76px] left-0 origin-left lg:hidden bg-white"
          >
            <VisuallyHidden as-child>
              <DialogTitle>
                {{ $t('menu') }}
              </DialogTitle>
            </VisuallyHidden>

            <Container
              class="h-full overflow-auto"
              inner-class="flex flex-col justify-between min-h-full gap-y-10 pb-7"
            >
              <!-- Mobile menu -->
              <nav class="w-full">
                <ul class="flex flex-col gap-y-5">
                  <li v-for="item in menu" :key="item.id" class="-mr-1">
                    <NuxtLinkLocale
                      v-if="!item.children?.length"
                      :to="`${item.to}`"
                      class="font-medium leading-tight text-35"
                    >
                      {{ item.title }}
                    </NuxtLinkLocale>
                    <!--- TODO refactor CollapsibleRoot -->
                    <CollapsibleRoot v-else v-slot="{ open }">
                      <CollapsibleTrigger
                        class="flex items-center justify-between w-full outline-none"
                      >
                        <span class="font-medium leading-tight text-35">{{
                          item.title
                        }}</span>
                        <div
                          aria-hidden="true"
                          :class="[
                            'transition duration-200 text-black',
                            { 'rotate-180': open },
                          ]"
                        >
                          <Icon name="keyboard_arrow_down" large />
                        </div>
                      </CollapsibleTrigger>

                      <Transition
                        enter-active-class="transition duration-200 ease-out"
                        enter-from-class="-translate-y-2"
                        enter-to-class="translate-y-0"
                        leave-active-class="transition duration-150 ease-in"
                        leave-from-class="translate-y-0"
                        leave-to-class="-translate-y-2"
                      >
                        <CollapsibleContent class="CollapsibleContent">
                          <ul class="flex flex-col pt-5 gap-y-5">
                            <li v-for="child in item.children" :key="child.id">
                              <NuxtLinkLocale
                                v-if="!child?.children?.length"
                                :to="`${child.to}`"
                                class="font-medium leading-tight text-26"
                              >
                                {{ child.title }}
                              </NuxtLinkLocale>
                              <CollapsibleRoot v-else v-slot="{ open }">
                                <CollapsibleTrigger
                                  class="flex items-center justify-between w-full pl-2 focus"
                                >
                                  <span
                                    class="font-medium leading-tight text-26"
                                    >{{ child.title }}</span
                                  >
                                  <div
                                    aria-hidden="true"
                                    :class="[
                                      'transition duration-200 text-black',
                                      { 'rotate-180': open },
                                    ]"
                                  >
                                    <Icon name="keyboard_arrow_down" large />
                                  </div>
                                </CollapsibleTrigger>
                                <Transition
                                  enter-active-class="transition duration-200 ease-out"
                                  enter-from-class="-translate-y-2"
                                  enter-to-class="translate-y-0"
                                  leave-active-class="transition duration-150 ease-in"
                                  leave-from-class="translate-y-0"
                                  leave-to-class="-translate-y-2"
                                >
                                  <CollapsibleContent
                                    class="CollapsibleContent"
                                  >
                                    <ul class="flex flex-col pt-5 gap-y-5">
                                      <li
                                        v-for="subChild in child.children"
                                        :key="subChild.id"
                                      >
                                        <NuxtLinkLocale
                                          :to="`${subChild.to}`"
                                          class="pl-4 font-medium leading-tight text-20"
                                        >
                                          {{ subChild.title }}
                                        </NuxtLinkLocale>
                                      </li>
                                    </ul>
                                  </CollapsibleContent>
                                </Transition>
                              </CollapsibleRoot>
                            </li>
                          </ul>
                        </CollapsibleContent>
                      </Transition>
                    </CollapsibleRoot>
                  </li>
                </ul>
              </nav>

              <!-- Service menu -->
              <div class="w-full">
                <div class="flex flex-col pb-4 gap-y-5">
                  <button
                    class="font-medium leading-tight text-26 text-start"
                    @click="
                      () => {
                        toggleMenuOpen()
                        setContactOpen(true)
                      }
                    "
                  >
                    {{ $t('contact') }}
                  </button>

                  <NuxtLinkLocale
                    to="imprint"
                    class="font-medium leading-tight text-26"
                  >
                    {{ $t('imprint') }}
                  </NuxtLinkLocale>
                </div>

                <!-- Copy -->
                <p class="font-medium leading-snug mt-7 text-14">
                  &copy; {{ now.getFullYear() }}
                </p>
              </div>
            </Container>
          </DialogContent>
        </Transition>
      </DialogPortal>
    </DialogRoot>

    <!-- Contact overlay -->
    <DialogRoot v-if="contactInfoData" v-model:open="contactOpen">
      <DialogPortal>
        <!-- Overlay background -->
        <Transition
          enter-active-class="duration-300 ease-out"
          enter-from-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="duration-200 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <DialogOverlay
            class="transition-opacity fixed z-[998] inset-0 bg-black/[0.93]"
          />
        </Transition>

        <!-- Overlay content -->
        <Transition
          enter-active-class="duration-300 ease-out"
          enter-from-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="duration-200 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <DialogContent
            class="fixed inset-0 z-[999] flex flex-col overflow-y-auto text-white transition-opacity selection-on-dark"
          >
            <VisuallyHidden as-child>
              <DialogTitle>
                {{ $t('contact') }}
              </DialogTitle>
            </VisuallyHidden>

            <!-- Head-->
            <div class="shrink-0 w-full h-[76px] lg:h-[106px] px-5 sm:px-12">
              <div class="flex items-center justify-between h-full gap-x-4">
                <div class="flex items-start">
                  <strong class="sr-only">welance</strong>
                  <WeLogo class="w-[22px] lg:w-[30px]" />
                  <WelanceLogo class="w-[85px] ml-1 lg:w-[112px] lg:ml-2" />
                </div>

                <!-- Closer -->
                <DialogClose as-child>
                  <div class="cursor-pointer">
                    <Icon name="cancel" x-large aria-hidden="true" />
                    <span class="sr-only">{{ $t('close-menu') }}</span>
                  </div>
                </DialogClose>
              </div>
            </div>

            <!-- Content -->
            <Container
              class="pt-10 pb-6 lg:pt-28 lg:pb-16 grow"
              inner-class="flex flex-col h-full gap-y-10 xl:gap-y-16"
            >
              <!-- Contact -->
              <div class="grid grid-cols-1 gap-10 md:grid-cols-2">
                <div
                  v-for="office in contactInfoData.offices"
                  :key="office.id"
                  class="p-2 flex flex-col max-w-[300px] space-y-2"
                >
                  <p class="font-medium text-35">{{ office?.title }}</p>
                  <p class="flex-1 text-24 text-wrap">
                    {{ nl2br(office?.address as string) }}
                  </p>
                  <a
                    class="text-20 leading-tight font-medium underline underline-offset-[.125em] break-words"
                    :href="`tel:${office.phone_number}`"
                    >t: {{ office.phone_number }}</a
                  >
                  <a
                    class="text-20 leading-tight font-medium underline underline-offset-[.125em] break-words"
                    :href="`mailto:${office.email}`"
                    >m: {{ office.email }}</a
                  >
                </div>
              </div>

              <!-- Copyright & Service menu -->
              <div
                class="flex justify-between mt-auto font-medium gap-x-4 text-14 lg:text-16"
              >
                <p class="whitespace-nowrap">&copy; {{ now.getFullYear() }}</p>

                <nav class="space-x-4">
                  <NuxtLinkLocale to="imprint">
                    {{ $t('imprint') }}
                  </NuxtLinkLocale>
                </nav>
              </div>
            </Container>
          </DialogContent>
        </Transition>
      </DialogPortal>
    </DialogRoot>
  </header>
</template>

<style>
@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-collapsible-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-collapsible-content-height);
  }
  to {
    height: 0;
  }
}
.CollapsibleContent {
  overflow: hidden;
}
.CollapsibleContent[data-state='open'] {
  animation: slideDown 300ms ease-out;
}
.CollapsibleContent[data-state='closed'] {
  animation: slideUp 200ms ease-in;
}

.headerMenu a,
.headerMenu .subnav-trigger {
  position: relative;
}

.headerMenu a::before,
.headerMenu a.activeMenuItem::before,
.headerMenu .subnav-trigger::before,
.headerMenu .subnav-trigger.activeMenuItem::before {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0.1em;
  left: 0;
  background-color: #000;
  transform: scaleX(0);
  transition: transform 0.2s ease;
}

.headerMenu a.activeMenuItem::before,
.headerMenu .subnav-trigger.activeMenuItem::before {
  transform: scaleX(1);
}

.headerMenu a:hover::before,
.headerMenu .subnav-trigger:hover::before {
  transform: scaleX(1);
}
</style>
