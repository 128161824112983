<script setup>
const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
})
const title = 'welance' // fallback title
</script>

<template>
  <Html :lang="head.htmlAttrs.lang" :dir="head.htmlAttrs.dir">
    <Head>
      <Title>{{ title }}</Title>
      <template v-for="link in head.link" :key="link.id">
        <Link
          :id="link.id"
          :rel="link.rel"
          :href="link.href"
          :hreflang="link.hreflang"
        />
      </template>
      <template v-for="meta in head.meta" :key="meta.id">
        <Meta :id="meta.id" :property="meta.property" :content="meta.content" />
      </template>
    </Head>

    <Body>
      <div>
        <NuxtLoadingIndicator />
        <BaseHeader />
        <div class="wrapper">
          <main class="pt-[76px] lg:pt-[106px]">
            <slot />
            <BaseFooter class="shrink-0" />
          </main>
        </div>
      </div>
    </Body>
  </Html>
</template>
